p {
    font-size: 2.5vh;
}

i {
    font-size: 2.5vh;
}

li {
    font-size: 2.5vh;
}

h2 {
    font-size: 3.5vh;
}